<template>
    <div class="pb-16">
        <!-- 배너 (Mobile) -->
        <BannerViewBoard
            v-if="$vuetify.breakpoint.mobile"
            type="privacy_mobile"
            :width="$vuetify.breakpoint.width"
            :height="$vuetify.breakpoint.width/2.7"
            title="PRIVACY"
            subtitle="개인정보처리방침"
        />

        <!-- 배너 (PC) -->
        <BannerViewBoard
            v-if="!$vuetify.breakpoint.mobile"
            type="privacy"
            width="1920"
            height="240"
            title="PRIVACY"
            subtitle="개인정보처리방침"
        />

        <!-- 본문 -->
        <div
            style="width:1200px; margin:0 auto;"
        >
            <v-sheet class="pa-4 rounded-10">
                <v-sheet v-if="$route.query.type=='read'">
                    <PolicyRead title="Privacy" />
                </v-sheet>
                <!-- <v-sheet v-if="$route.query.type=='write' && $store.state.type=='관리자'"> -->
                <v-sheet v-if="$route.query.type=='write'">
                    <PolicyWrite title="Privacy" />
                </v-sheet>
            </v-sheet>
        </div>
    </div>
</template>
<script>
import BannerViewBoard from '@/components/BannerViewBoard'
import PolicyRead from './components/Read'
import PolicyWrite from './components/Write'

export default {
    components: {
        BannerViewBoard,
        PolicyRead,
        PolicyWrite
    }
}
</script>